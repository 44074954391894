import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video'
import lgZoom from 'lightgallery/plugins/zoom'
import Player from '@vimeo/player';

export default function init() {
  const lightGalleryContainers = document.querySelectorAll('.js-lightgallery');
  const inlineVideoFrames = document.querySelectorAll('iframe.c-gallery-page-item__iframe');

  lightGalleryContainers.forEach((containerValue) => {
    lightGallery(containerValue, {
      licenseKey: 'E98496B8-C2DA4C50-B5800A51-C4FC70D0',
      selector: '.js-lightgallery-selector',
      actualSize: true,
      plugins: [lgVideo, lgZoom],
      autoplayVideoOnSlide: true,
      gotoNextSlideOnVideoEnd: false,
      download: false,
      getCaptionFromTitleOrAlt: false,
    });

    containerValue.addEventListener('lgAfterClose', () => {
      inlineVideoFrames.forEach(iframe => {
        if (!iframe.player) {
          iframe.player = new Player(iframe);
        }
        iframe.player.play();
      })
    });
  });
}
