import { playPauseVideo } from './carousel-hero.js';
export default function init(){
    var query = window.matchMedia('(min-width: 1200px)');
    /**
     * @param {HTMLVideoElement} video
     */
    var isVideoActive = function (video) {
        var isInCarousel = video.closest('.js-hero-carousel');
        if (!isInCarousel) {
            return true;
        }

        // If the video is in a carousel, it may not be the currently selected slide
        return video.closest('.flickity-slider > .item').classList.contains('is-selected');
    };
    var toggleVideos = function (e) {
        var isDesktop = e.matches;
        document
            .querySelectorAll('.js-header-video')
            .forEach(function (video) {
                playPauseVideo(
                    video,
                    isVideoActive(video) && !video.classList.contains(isDesktop ? 'is-mobile' : 'is-video')
                );
            });
    };

    query.addEventListener ? query.addEventListener('change', toggleVideos) : query.addListener(toggleVideos);
    toggleVideos(query);
}
