const getNewCsrf = () => {
  $.ajax({
    url: '/actions/blitz/csrf/token',
  })
    .done((res) => {
      setAllCsrfInputValues(res);
    });
}
const setAllCsrfInputValues = (csrf) => {
  const forms = document.querySelectorAll('form.fui-form');
  forms.forEach((form) => {
    const input = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
    input.value = csrf;
  });
}

export default function init() {
  getNewCsrf();
}
