export default function init() {
    $('.information-blocks-tabs__tab').click(function () {

        var tab = $(this);
        var tabIdToShow = tab.data('tab-select-name');
        tab.siblings().removeClass('active');
        tab.addClass('active');

        $('.information-blocks-tabs__panel-wrap').find('.information-blocks-tabs__panel').removeClass('active');
        $('.information-blocks-tabs__panel[data-tab-id=' + tabIdToShow + ']').addClass('active');
    });
}
