import Flickity from 'flickity';
import 'flickity/dist/flickity.css';
import Player from '@vimeo/player';

/**
 * Handles the hero carousel
 * Presumes that 1 slide has 1 cell, if this changes this will need to be refactored
 */

/**
 * Also used by fancy-header.js
 *
 * @param {HTMLVideoElement|HTMLIFrameElement} video
 * @param {boolean} play True to play, false to pause
 */
export const playPauseVideo = (video, play) => {
  // Native video
  if (video.tagName === 'VIDEO') {
    play ? video.play() : video.pause();
    return;
  }

  // iFrame video - currently only Vimeo
  var player = new Player(video);
  if (player) {
    play ? player.play() : player.pause();
  }
}

const toggleVideos = (flickity) => {
  flickity.on('change', () => {
    var slides = flickity.slides;
    // Loop through all the slides - play the current video, pause the thers
    slides.forEach((slide, slideIndex) => {
      /** @type {HTMLElement} el */
      var el = slide.cells[0].element;
      var video = el.querySelector('.js-header-video'); // May be native or iframe
      if (video) {
        playPauseVideo(video, slideIndex === flickity.selectedIndex);
      }
    });
  });
}

export default function init() {
  var elem = document.querySelector('.js-hero-carousel');
  if (elem) {
    var hasVideo = elem.querySelector('.js-header-video');
    var flickity = new Flickity(elem, {
      prevNextButtons: true,
      contain: true,
      adaptiveHeight: true,
      autoPlay: hasVideo ? false : 5000,
      wrapAround: true,
      pauseAutoPlayOnHover: false,
    });

    if (hasVideo) {
      toggleVideos(flickity);
    }
  }
}
