export default function init() {
  $('.sharing').on('click', '.sharing-facebook', function() {
    shareFacebook();
  }).on('click', '.sharing-twitter', function() {
    shareTwitter();
  });
}

const shareWindowProperties = (width, height) => {
  let leftPosition, topPosition;
  leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
  topPosition = (window.screen.height / 2) - ((height / 2) + 50);
  return "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
}

const shareTwitter = () => {
  let windowProperties = shareWindowProperties(400, 300);
  window.open('https://twitter.com/home?status='+encodeURIComponent(location.href),'share-twitter', windowProperties);
  return false;
}

const shareFacebook = () => {
  let windowProperties = shareWindowProperties(400, 300);
  window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(location.href)+'&t='+encodeURIComponent(document.title),'share-facebook', windowProperties);
  return false;
}
