import Flickity from 'flickity';
import 'flickity/dist/flickity.css'
;
export default function init() {
  var elem = document.querySelector('.js-tweet-carousel');
  if (elem) {
    new Flickity(elem, {
      lazyLoad: true,
      prevNextButtons: false,
      contain: true,
    });
  }
}
