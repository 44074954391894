import ImageCompare from "image-compare-viewer";
import 'image-compare-viewer/dist/image-compare-viewer.min.css'

export default function init() { 
  document.querySelectorAll('.js-before-and-after-slider').forEach((el) => {
    new ImageCompare(el, {
      addCircle: true,
      addCircleBlur: false,
      showLabels: true,
      labelOptions: {
        before: 'Before',
        after: 'After',
      },
    }).mount();
  });
}
