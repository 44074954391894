export default function init() {
  const feedDiv = document.querySelector('.five-image-block');
  if (feedDiv) {
    fetch('/actions/instagramfetch-module/instagram-fetch/get-feed')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        Object.values(data.data).forEach((element, index) => {
          const link = document.createElement('a');
          link.setAttribute('href', element.permalink);
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
          link.setAttribute('aria-label', 'Link to ' + element.caption);
          link.setAttribute(
            'class',
            index > 3
              ? 'five-image-block__a five-image-block__a--gt3'
              : 'five-image-block__a'
          );
          feedDiv.appendChild(link);
          const image = document.createElement('img');
          image.setAttribute('class', 'five-image-block__image');
          image.setAttribute(
            'src',
            element.media_type === 'VIDEO'
              ? element.thumbnail_url
              : element.media_url
          );
          image.setAttribute('loading', 'lazy');
          image.setAttribute('alt', element.caption);
          link.appendChild(image);
        });
      });
  }
}
