import Flickity from 'flickity';
import 'flickity/dist/flickity.css';

/**
 * @param {boolean} resize - resize the flickity carousel if not using window.load as flickity cannot calculate the dimensions of lazy loaded images otherwise
 */
const initCarousels = (resize = false) => {
  const carousels = document.querySelectorAll('.js-news-article-carousel');
  if (carousels.length) {
    for (let index = 0; index < carousels.length; index++) {
      const carouselEl = carousels[index];
      const flkty = new Flickity(carouselEl, {
        cellAlign: 'left',
        contain: true,
        lazyLoad: true,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        autoPlay: false,
        adaptiveHeight: true,
      });
      $(carouselEl).siblings('.latest-news-articles__navigate').find('.js-news-article-carousel--next').on('click', () => {
        flkty.next();
      });
      $(carouselEl).siblings('.latest-news-articles__navigate').find('.js-news-article-carousel--prev').on('click', () => {
        flkty.previous();
      });
      if (resize) {
        setTimeout(() => {
          flkty.resize();
        }, 500);
      }
    }
  }
}

export default function init() {
  if (document.readyState === 'complete') {
    initCarousels(true);
  } else {
    $(window).on('load', function() {
      initCarousels();
    });
  }
}
