export default function init(){
    var containerWidth = $('.js-video-embed').width();
    var height = containerWidth * (9 / 16);
    $('.js-video-embed').height(height);


    $(window).on('resize', function(){
        var containerWidth = $('.js-video-embed').width();
        var height = containerWidth * (9 / 16);

        $('.js-video-embed').height(height);
    });
}
