export default function init() {
    $('html').click(function(e){
        if ($(e.target).closest(".bm-dropdown").length) {
            $('.bm-dropdown__select').toggleClass('bm-dropdown__select--active');
        } else {
            $('.bm-dropdown__select').removeClass('bm-dropdown__select--active');
        }
    });

    $('.bm-dropdown__select-value').click(function(){

        if (!$(this).hasClass('bm-dropdown__select-value--selected') && $(this).data('link')) {
            window.location = $(this).data('link');
        }

        $('.bm-dropdown__select-value').removeClass('bm-dropdown__select-value--selected');
        $(this).addClass('bm-dropdown__select-value--selected');
        $("input[name='bm-dropdown-selected-value']").val($(this).data('bm-dropdown-selected-value'));

    });
}
