export default function init() {

    $('html').click(function (e) {

        if ($(e.target).closest(".nav__search-wrap").length) {
            return;
        } else if ($(e.target).closest(".nav-link-search").length) {

            if( $('.nav__search-wrap').hasClass('navbar__search-wrap--active')){
                $('.nav__search-wrap form').submit();

            }else{
                $('.nav__search-wrap').toggleClass('navbar__search-wrap--active');

                setTimeout(function(){
                    $('.navbar__search-input').focus();
                }, 100);
            }
        } else {

            $('.nav__search-wrap').removeClass('navbar__search-wrap--active');

        }
    });

}
