import '@styles/app.scss';

import beforeAndAfterSlider from '@modules/before-and-after-slider';
import bmStarRating from '@modules/bm-star-rating';
import callToActionAccordion from '@modules/call-to-action-accordion';
import carouselHero from '@modules/carousel-hero';
import carouselTweet from '@modules/carousel-tweet';
import carouselNewsArticle from '@modules/carousel-news-article';
import carousel from '@modules/carousel';
import customDropdownSelect from '@modules/custom-dropdown-select';
import dropdownMenu from '@modules/dropdown-menu';
import fancyHeader from '@modules/fancy-header';
import formCsrf from '@modules/form-csrf';
import formTracking from '@modules/form-tracking';
import galleryPage from '@modules/gallery-page';
import galleryMatrixBlock from '@modules/gallery-matrix-block';
import galleryDynamicMatrixBlock from '@modules/gallery-dynamic-matrix-block';
import informationBlockTabs from '@modules/information-block-tabs';
import largeVideoCallToAction from '@modules/large-video-call-to-action';
import mobileNav from '@modules/mobile-nav';
import navSearchButton from '@modules/nav-search-button';
import scrollr from '@modules/scrollr';
import sharing from '@modules/sharing';
import sidebarCategories from '@modules/sidebar-categories';
import instagramLoader from '@modules/instagram-loader';
import lightgallery from '@modules/lightgallery';

beforeAndAfterSlider();
bmStarRating();
callToActionAccordion();
carouselHero();
carouselTweet();
carouselNewsArticle();
carousel();
customDropdownSelect();
dropdownMenu();
fancyHeader();
formCsrf();
formTracking();
galleryPage();
galleryMatrixBlock();
galleryDynamicMatrixBlock();
informationBlockTabs();
largeVideoCallToAction();
mobileNav();
navSearchButton();
scrollr();
sharing();
sidebarCategories();
instagramLoader();
lightgallery();
