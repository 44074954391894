import 'jquery.mmenu/dist/css/jquery.mmenu.all.css';
import 'jquery.mmenu';

export default function init() {
  var socialLinks = [];

  if ($("meta[name=contact]").length) {
    socialLinks.push(
      "<a class='fa fa-envelope' href='mailto:" +
        $("meta[name=contact]").attr("content") +
        "'></a>"
    );
  }
  if ($('meta[name="twitter:site"]').length) {
    socialLinks.push(
      "<a class='fa fa-twitter' href='https://twitter.com/" +
        $('meta[name="twitter:site"]').attr("content") +
        "'></a>"
    );
  }
  if ($("meta[name=facebook-url]").length) {
    socialLinks.push(
      "<a class='fa fa-facebook' href='" +
        $("meta[name=facebook-url]").attr("content") +
        "'></a>"
    );
  }

  if ($.fn.mmenu) {
    var menu = $("nav#mobile-menu")
      .mmenu({
        extensions: ["pagedim-black", "theme-dark"],
        navbars: [
          {
            position: "top",
          },
          {
            position: "bottom",
            content: socialLinks,
          },
        ],
      })
      .data("mmenu");

    var $burger = $(".navbar-mobile__mobile-menu-button")
      .on("click", function (e) {
        e.preventDefault();
        if ($("html").hasClass("mm-opened")) {
          menu.close();
        } else {
          menu.open();
        }
      })
      .children(".hamburger");

    if (menu) {
      menu.bind("opening", function () {
        $burger.addClass("is-active");
      });
      menu.bind("closing", function () {
        $burger.removeClass("is-active");
      });
    }
  }
}
