export default function init() {
    $('.call-to-action-accordion__accordion-title').click(function() {
        $('.call-to-action-accordion__accordion-title').removeClass('active');
        $(this).addClass('active');

        var img = $(this).data('image');

        $('.call-to-action-accordion__image').animate({
            opacity: 0
        }, 350, function() {
            $('.call-to-action-accordion__image').on('load', function() {
                $('.call-to-action-accordion__image').animate({
                    opacity: 1
                },350);
            }).attr('src', img);
        });
    });
}
