import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

const setMasonry = ($grid, columnWidth = 304) => {
  const msnry = new Masonry($grid, {
    itemSelector: '.js-gallery-dynamic-item',
    columnWidth: columnWidth,
    gutter: 18,
    fitWidth: true,
  });
  return msnry;
}

export default function init() {
  // Masonry grid
  const grids = document.querySelectorAll('.js-gallery-dynamic-items');
  if (grids.length) {
    for (let index = 0; index < grids.length; index++) {
      const grid = grids[index];
      // Set grid col width based on window width
      const msnry = setMasonry(grid, $(window).outerWidth() > 400 ? 304 : 300);

      // Reset the masonry layout when the images have loaded
      const imgLoad = imagesLoaded(grid);
      imgLoad.on('progress', function() {
        msnry.layout();
      });

      // Reset the masonry layout when the videos have loaded
      document.querySelectorAll('video').forEach((vid) => {
        vid.addEventListener('loadeddata', () => {
          msnry.layout();
        });
      });

      // On resize event change masonry col width based on window width
      var resizeTimer;
      $(window).on('resize', function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
          setMasonry(grid, $(window).outerWidth() > 400 ? 304 : 300);
        }, 250);
      });
    }
  }
}
