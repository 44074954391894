
const createStars = ({ $inputContainer: $inputContainer, $input: $input }) => {
  var $starsContainer = $('<div class="bm-star-rating__wrap"></div>');
  var stars = [];
  for (var index = 0; index < 5; index++) {
    var $star = $('<span class="icon-bm-star bm-star-rating__star"></span>')
    stars.push($star);

    $star.on('click', function(){
      var $this = $(this);
      $input.val($this.index() + 1);
      $('.bm-star-rating__star').removeClass('icon-bm-star--active');

      $(stars).each(function(starIndex, starsStarEl) {
        if (starIndex <= $this.index()) {
          $(starsStarEl).addClass('icon-bm-star--active')
        }
      });

    });
    $starsContainer.append($star)
  }
  $inputContainer.append($starsContainer);
}

export default function init() {
  var $container = $('.js-bm-star-rating');
  var $inputContainer = $container.find('.fui-input-container');
  var $input = $inputContainer.find('[name="fields[starRating]"]');
  $input.attr('type', 'hidden');
  createStars({ $inputContainer: $inputContainer, $input: $input });
}
