/**
 * The client login form is excluded from cross-domain tracking because GA4 manipulates the 
 * action attribute from a fixed value to: 
 * "[object HTMLInputElement]?_gl=1*18905in*_up*MQ..*_ga*OTY0NTQyNTcuMTcxODYyNDIxOQ..*_ga_GXYFNLSWHV*MTcxODYyNDIxOS4xLjAuMTcxODYyNDIxOS4wLjAuMA.." 
 * leading the destination url to become "https://www.prodoto.com/[object%20HTMLInputElement]?_gl=1*18905in*_up*MQ..*_ga*OTY0NTQyNTcuMTcxODYyNDIxOQ..*_ga_GXYFNLSWHV*MTcxODYyNDIxOS4xLjAuMTcxODYyNDIxOS4wLjAuMA.." which naturally... 404s.
 */
function excludeClientLoginFromCrossDomainTracking() {
  const clientLoginForm = document.getElementById('form');
  if (!clientLoginForm) return;
  const originalActionUrl = clientLoginForm.action;
  clientLoginForm.onsubmit = function() {
    const actionUrl = clientLoginForm.action;
    
    // Check if the action URL contains [object HTMLInputElement]
    if (actionUrl.includes('[object HTMLInputElement]')) {
        console.error('Resetting action to original, invalid action URL detected:', actionUrl);
        clientLoginForm.action = originalActionUrl;
    }
  }
}

export default function init() {
  window.dataLayer = window.dataLayer || [];

  const forms = document.querySelectorAll('.fui-form');
  forms.forEach(/** @param {HTMLFormElement} form */ (form) => {
    form.addEventListener('onAfterFormieSubmit', (e) => {
        e.preventDefault();
        const response = e.detail;
        if (response.success) {
          // Datalayer tracking - GA4
          {
            const handleInputEl = form.querySelector('input[name="handle"]');
            const tracking = {
              event: 'form_submission',
              form_handle: handleInputEl.value,
            };

            // How did you find us
            const howDidYouFindUs = form.querySelector('[name="fields[howDidYouFindUs]"]');
            if (howDidYouFindUs) {
              tracking.how_did_you_find_us = howDidYouFindUs.value;
            }

            // Star rating
            const starRating = form.querySelector('[name="fields[starRating]"]');
            if (starRating) {
              tracking.star_rating = starRating.value;
            }

            // Marketing opt in
            const marketingOptIn = form.querySelector('[type="checkbox"][name="fields[yesIWouldLikeToReceiveNewsFromProdoto]"]');
            if (marketingOptIn) {
              tracking.marketing_opt_in = marketingOptIn.checked;
            }

            // Add to datalayer
            window.dataLayer.push(tracking);
          }

          // LinkedIn tracking
          const conversionId = form.dataset.linkedInConversionId;
          if (conversionId) {
            if (!window.lintrk) {
              window.lintrk = function(a,b){
                window.lintrk.q.push([a,b])
              };
              window.lintrk.q = [];
            }
            window.lintrk('track', { conversion_id: conversionId });
          }
        }
    });

  });

  excludeClientLoginFromCrossDomainTracking();
}
