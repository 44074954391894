export default function init() {
    var timer = 0;
    $(window).scroll(function () {

        if (timer) clearTimeout(timer);

        timer = setTimeout(function () {
            // Make your AJAX request here...
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 30 && !$('.navbar').hasClass('navbar-scrolled')) {

                $('.navbar').addClass('navbar-scrolled');
                $('.navbar').find('.navbar__logo').addClass('navbar__logo--hide');
                $('.navbar').find('.navbar__logo-scrolled').addClass('navbar__logo-scrolled--show');
                $('.navbar').find('.navbar__search-input').addClass('navbar__search-input--scrolled');
                return;

            } else if (scrollTop < 31) {
                $('.navbar').removeClass('navbar-scrolled');
                $('.navbar').find('.navbar__logo').removeClass('navbar__logo--hide');
                $('.navbar').find('.navbar__logo-scrolled').removeClass('navbar__logo-scrolled--show');
                $('.navbar').find('.navbar__search-input').removeClass('navbar__search-input--scrolled');
            }

        }, 50);
    });
}
